
.form-item a {
  display: table;
  margin: 0 auto;
  color: initial;
  text-decoration: none;
}

.form-item {
  margin-top: 24px;
}

a {
  text-decoration: none;
}
