.panel-action {
  color: #666666;
  margin-left: 24px;
  cursor: pointer;

}

.panel-action.aactive {
  border-bottom: 3px solid blue;
}

.controller-button {
  width: 64px;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 64px;
  height: 64px;
  margin-left: 24px;
  margin-bottom: 24px;
  line-height: 64px;
  display: inline-block;
  text-align: center;
}
