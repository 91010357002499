.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: float 2s ease-in-out infinite;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Hero-shadow svg {
  animation: float-shadow 2s ease-in-out infinite;
  height: 25px;
}

.App-header a {
  font-size: 12px;
}

.Hero-form {
  margin-top: 12px;
}
.Hero-form input {
  font-size: 24px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 12px;
}

.Hero-form button {
  font-size: 24px;
  line-height: 24px;
  width: 100%;
}

@keyframes float-shadow {
  0% {
    width: 100%;
    height: 25px;
  }
  50% {
    width: 85%;
    height: 23px;
  }
  100% {
    width: 100%;
    height: 25px;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
