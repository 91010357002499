.auth-form-floater {
  display: table;
  margin: 120px auto;
  width: 250px;
}

.auth-form-floater button {
  width: 100%
}

.auth-form-floater .form-item {
  margin-top: 24px;
}

.form-item input {
  height: 32px;
  width: 100%;
  box-sizing: border-box;
}

.form-item button {
  height: 32px
}

.form-item a {
  display: table;
  margin: 0 auto;
  color: initial;
  text-decoration: none;
}
